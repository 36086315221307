import * as React from "react";
import { Link } from "gatsby";

// import Bridge from "../../components/Bridge.js";
import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function TwentyGoodReasons() {
  return (
    <Layout>
      <Helmet title="20 Good Reasons | Thirsty Merc" />

      <h1>20 Good Reasons</h1>

      <h2>Thirsty Merc</h2>

      <h4>Key: C</h4>

      <h4>Tempo: 92</h4>

      <p>Non-ASCAP</p>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>
            <Ch>C</Ch>They say love hurts <Ch>G</Ch>I wrote that book
          </p>
          <p>
            <Ch>Am</Ch>I climbed that wall, <Ch>F</Ch>I had one look
          </p>
          <p>
            <Ch>C</Ch>But you just came a<Ch>G</Ch>round, to say <Ch>Am</Ch>
            hello <Ch>F</Ch>
          </p>
        </Verse>

        <Verse>
          <p>
            <Ch>C</Ch>The streets were filled <Ch>G</Ch>with guilty hearts
          </p>
          <p>
            And <Ch>Am</Ch>here was I, right from the <Ch>F</Ch>start and
          </p>
          <p>
            <Ch>C</Ch>I lost every<Ch>G</Ch>thing, when I lost<Ch>Am</Ch> you{" "}
            <Ch>F</Ch>
          </p>
        </Verse>

        <Chorus>
          <p>
            So tell me <Ch>C</Ch>why
          </p>
          <p>
            <Ch>G</Ch>Should I let <Ch>Am</Ch>you go <Ch>F</Ch>
          </p>
          <p>
            Give me <Ch>C</Ch>20 good reason<Ch>G</Ch>s
          </p>
          <p>
            I need to<Ch>Am</Ch> know<Ch>F</Ch>
          </p>
        </Chorus>

        <Verse>
          <p>
            And <Ch>C</Ch>at that point, there <Ch>G</Ch>was no pain
          </p>
          <p>
            <Ch>Am</Ch>There was no sky, there <Ch>F</Ch>was no rain
          </p>
          <p>
            <Ch>C</Ch>All there was was <Ch>G</Ch>you, and your sweet{" "}
            <Ch>Am</Ch>face <Ch>F</Ch>
          </p>
        </Verse>

        <Verse>
          <p>
            But <Ch>C</Ch>life is life, and <Ch>G</Ch>things will change
          </p>
          <p>
            Like <Ch>Am</Ch>scenes upon, an <Ch>F</Ch>actor's stage
          </p>
          <p>
            <Ch>C</Ch>Tomorrow comes to<Ch>G</Ch>day, for all we <Ch>Am</Ch>know{" "}
            <Ch>F</Ch>
          </p>
        </Verse>

        <Chorus>
          <p>
            So tell me <Ch>C</Ch>why
          </p>
          <p>
            <Ch>G</Ch>Should I let <Ch>Am</Ch>you go <Ch>F</Ch>
          </p>
          <p>
            Give me <Ch>C</Ch>20 good reason<Ch>G</Ch>s
          </p>
          <p>
            I need to<Ch>Am</Ch> know<Ch>F</Ch>
          </p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
